import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { authMessages } from '@coligo-org/fe-common/translations';
import { zodResolver } from '@hookform/resolvers/zod';
import cx from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  LockOutlined,
  Visibility,
  VisibilityOff,
  HighlightOff as ErrorIcon,
} from '@mui/icons-material';
import {
  Grid,
  Avatar,
  CssBaseline,
  FormControl,
  Paper,
  Typography,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import useRoles from 'hooks/useRoles';
import getCustomSchoolData from 'custom/schools';
import LoadableButton from '../../../common/components/LoadableButton';
import styles from './styles';
import { signIn } from '../../state/actions/auth';
import packageInfo from '../../../../package.json';
import getDefaultRoute from '../../../app/helpers/getDefaultRoute';
import { getCurrentUser } from '../../services/authService';
import Request from '../ForgotPassword/Request';
import ChooseAccount from '../ForgotPassword/ChooseAccount';
import Pending from '../ForgotPassword/Pending';
import validate from './validate';

const expiredJWTQueryParam = 'expired';
const deactivatedUserQueryParam = 'deactivated';

const customSchoolData = getCustomSchoolData();

function LoginForm({ classes, login, errorMessage, isAuthenticatingUser }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { search } = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [isCapsLockEnabled, setIsCapsLockEnabled] = useState(false);
  const [isDeactivatedUser, setIsDeactivatedUser] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [showChooseAccount, setShowChooseAccount] = useState(false);
  const [showPending, setShowPending] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [chosenAccount, setChosenAccount] = useState();

  const { allRoles } = useRoles();

  const queryParams = new URLSearchParams(search);
  const validationSchema = validate(intl);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = async data => {
    if (queryParams.has(expiredJWTQueryParam)) {
      queryParams.delete(expiredJWTQueryParam);
      navigate('', { replace: true, search: queryParams.toString() });
    }
    if (queryParams.has(deactivatedUserQueryParam)) {
      queryParams.delete(deactivatedUserQueryParam);
      navigate('', { replace: true, search: queryParams.toString() });
    }
    const newProps = { ...data, username: data.username.trim() };
    await login(
      newProps,
      defaultRoute => navigate(defaultRoute, { replace: true }),
      () => setIsDeactivatedUser(true),
      allRoles,
    );
  };

  const handleCapsLock = event => {
    if (event && event.getModifierState && event.getModifierState('CapsLock')) {
      setIsCapsLockEnabled(true);
    } else {
      setIsCapsLockEnabled(false);
    }
  };
  useEffect(() => {
    const user = getCurrentUser();
    if (Object.keys(user).length) {
      navigate(getDefaultRoute(user, allRoles), { replace: true });
    }
  }, [allRoles, navigate]);

  const expiryStatus = queryParams.get(expiredJWTQueryParam);
  const deactivationStatus = queryParams.get(deactivatedUserQueryParam);
  const showWarnings = {
    1: true,
  };

  return (
    <main
      className={cx(
        classes.main,
        customSchoolData && customSchoolData.logo && classes.mainWithLogo,
      )}
    >
      <CssBaseline />
      {customSchoolData && (
        <img
          className={classes.schoolImage}
          src={customSchoolData.logo}
          alt={customSchoolData.name}
        />
      )}
      {showLogin && (
        <Paper className={classes.paper}>
          {showWarnings[expiryStatus] && (
            <Grid align="center" className={classes.warning}>
              <ErrorIcon />
              <Typography variant="subtitle1">
                <FormattedMessage {...authMessages.expirationWarning} />
              </Typography>
            </Grid>
          )}
          {(showWarnings[deactivationStatus] || isDeactivatedUser) && (
            <Grid align="center" className={classes.warning}>
              <ErrorIcon />
              <Typography variant="subtitle1">
                <FormattedMessage {...authMessages.deactivationWarning} />
              </Typography>
            </Grid>
          )}
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h4">
            <FormattedMessage {...authMessages.header} />
          </Typography>
          <Typography variant="caption" align="center">
            {`version ${packageInfo.version}`}
          </Typography>
          <form
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
            data-testid="loginForm"
          >
            <FormControl variant="standard" margin="normal" fullWidth>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="standard"
                    placeholder={intl.formatMessage({
                      ...authMessages.userNamePlaceHolder,
                    })}
                    label={intl.formatMessage({
                      ...authMessages.userNameLabel,
                    })}
                    {...field}
                    error={Boolean(errors.username?.message)}
                    helperText={errors.username?.message}
                  />
                )}
              />
            </FormControl>
            <FormControl variant="standard" margin="normal" fullWidth>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="standard"
                    placeholder={intl.formatMessage({
                      ...authMessages.passwordPlaceHolder,
                    })}
                    onKeyDown={handleCapsLock}
                    type={showPassword ? 'text' : 'password'}
                    label={intl.formatMessage({
                      ...authMessages.passwordLabel,
                    })}
                    onBlur={() => setIsCapsLockEnabled(false)}
                    error={Boolean(errors.password?.message)}
                    helperText={errors.password?.message}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment
                            style={{ position: 'absolute', right: 2 }}
                            position="end"
                          >
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              size="large"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            {isCapsLockEnabled && (
              <FormHelperText>CapsLock is enabled</FormHelperText>
            )}
            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}
            <LoadableButton
              transparent
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isAuthenticatingUser}
              isLoading={isAuthenticatingUser}
            >
              <FormattedMessage {...authMessages.button} />
            </LoadableButton>
            <Typography
              className={classes.hyperLink}
              onClick={() => {
                setForgetPassword(true);
                setShowLogin(false);
              }}
            >
              Forgot your username/password?
            </Typography>
          </form>
        </Paper>
      )}
      {forgetPassword && (
        <Request
          backToLogin={() => {
            setShowLogin(true);

            setForgetPassword(false);
            setShowPending(false);
            setShowChooseAccount(false);
          }}
          goToChooseAccount={data => {
            setShowChooseAccount(true);

            setForgetPassword(false);
            setShowLogin(false);
            setShowPending(false);
            setAccounts(data);
          }}
          goToPending={data => {
            setChosenAccount(data);
            setShowPending(true);

            setForgetPassword(false);
            setShowLogin(false);
            setShowChooseAccount(false);
          }}
        />
      )}
      {showChooseAccount && (
        <ChooseAccount
          backToRequest={() => {
            setShowLogin(false);
            setForgetPassword(true);
            setShowPending(false);
            setShowChooseAccount(false);
          }}
          goToPending={data => {
            setShowPending(true);
            setForgetPassword(false);
            setShowLogin(false);
            setShowChooseAccount(false);
            setChosenAccount(data);
          }}
          accounts={accounts}
        />
      )}
      {showPending && <Pending account={chosenAccount} />}
    </main>
  );
}

LoginForm.propTypes = {
  classes: PropTypes.shape().isRequired,
  login: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isAuthenticatingUser: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  errorMessage: state.auth.errorMessage,
  isAuthenticatingUser: state.auth.isAuthenticatingUser,
});
const mapDispatchToProps = dispatch => ({
  login: (formProps, callback, errorCallback, allRoles) =>
    dispatch(signIn(formProps, callback, errorCallback, allRoles)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(LoginForm);
