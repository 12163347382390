const moduleName = 'announcements';

const CREATE_ANNOUNCEMENT_START = `${moduleName}/CREATE_ANNOUNCEMENT_START`;
const CREATE_ANNOUNCEMENT_END = `${moduleName}/CREATE_ANNOUNCEMENT_END`;

const GET_ANNOUNCEMENT_START = `${moduleName}/GET_ANNOUNCEMENT_START`;
const GET_ANNOUNCEMENT_END = `${moduleName}/GET_ANNOUNCEMENT_END`;
const SET_ANNOUNCEMENT = `${moduleName}/SET_ANNOUNCEMENT`;

const DELETE_ANNOUNCEMENT_START = `${moduleName}/DELETE_ANNOUNCEMENT_START`;
const DELETE_ANNOUNCEMENT_END = `${moduleName}/DELETE_ANNOUNCEMENT_END`;

const UPDATE_ANNOUNCEMENT_START = `${moduleName}/UPDATE_ANNOUNCEMENT_START`;
const UPDATE_ANNOUNCEMENT_END = `${moduleName}/UPDATE_ANNOUNCEMENT_END`;

const FETCH_COURSES_START = `${moduleName}/FETCH_COURSES_START`;
const FETCH_COURSES_END = `${moduleName}/FETCH_COURSES_END`;
const SET_COURSES = `${moduleName}/SET_COURSES`;

export default {
  GET_ANNOUNCEMENT_START,
  GET_ANNOUNCEMENT_END,
  SET_ANNOUNCEMENT,

  CREATE_ANNOUNCEMENT_START,
  CREATE_ANNOUNCEMENT_END,

  UPDATE_ANNOUNCEMENT_START,
  UPDATE_ANNOUNCEMENT_END,

  DELETE_ANNOUNCEMENT_START,
  DELETE_ANNOUNCEMENT_END,

  FETCH_COURSES_START,
  FETCH_COURSES_END,
  SET_COURSES,
};
